import { useQuery, useMutation } from "react-query";

import {
  IBookAppointmentForm,
  ICareMember,
  TCareMemberAvailableDates,
  TCareMemberAvailableDatesAndTimes,
} from "src/types";

import { QUERY_KEYS } from "./constants";
import { useApiFetch } from "./useApiFetch";

export const useAllCareProviderList = (enabled?: boolean) => {
  const apiFetch = useApiFetch();

  return useQuery(
    [QUERY_KEYS.FETCH_CARE_PROVIDER_LIST],
    async (): Promise<ICareMember[]> => {
      const {
        data: { users },
      } = await apiFetch(`/mp/providers/all`);
      return users;
    },
    { enabled }
  );
};

export const useGetCareProvider = (providerId: string) => {
  const apiFetch = useApiFetch();

  return useQuery(
    [QUERY_KEYS.FETCH_CARE_PROVIDER, providerId],
    async (): Promise<ICareMember> => {
      const { data } = await apiFetch(`/mp/providers/${providerId}`);
      return data;
    }
  );
};

export const useCareMemberAvailableTimesByUserId = (userId: Maybe<string>) => {
  const apiFetch = useApiFetch();

  return useQuery(
    [QUERY_KEYS.FETCH_PROVIDER_AVAILABLE_TIMES, userId],
    async (): Promise<TCareMemberAvailableDatesAndTimes> => {
      if (!userId) {
        return [];
      }

      const { data } = await apiFetch(`/acuity/${userId}/available-times`);
      return data;
    }
  );
};

export const useCareMemberAvailableDatesByUserId = (userId: Maybe<string>) => {
  const apiFetch = useApiFetch();

  return useQuery(
    [QUERY_KEYS.FETCH_PROVIDER_AVAILABLE_DATES, userId],
    async (): Promise<TCareMemberAvailableDates> => {
      if (!userId) {
        return [];
      }

      const { data } = await apiFetch(`/acuity/${userId}/available-dates`);
      return data;
    }
  );
};

export const useBookAppointment = () => {
  const apiFetch = useApiFetch();

  return useMutation(
    (appointmentForm: IBookAppointmentForm) =>
      apiFetch("/acuity/appointments", {
        method: "POST",
        data: appointmentForm,
      }),
    {
      mutationKey: QUERY_KEYS.BOOK_APPOINTMENT,
    }
  );
};
