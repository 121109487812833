import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import {
  useAddPharmacyPatient,
  useQueryParams,
  useSubmitFeedback,
} from "src/api";
import { ROUTES } from "src/app/types";
import Container from "src/components/Container";
import CompleteSurvey from "src/components/Survey/CompleteSurvey";
import { useAuth } from "src/hooks/useAuth";
import { Theme } from "src/theme/types/createPalette";
import { TFeedbackRequest } from "src/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.backgroundGreen.main,
    },
  })
);

const PharmacyCompletePage: FC = () => {
  const classes = useStyles();
  const { user } = useAuth();

  const history = useHistory();
  const queryParams = useQueryParams();
  const pharmacy = queryParams.get("pharmacy");

  const submitFeedback = useSubmitFeedback();
  const addPharmacyPatient = useAddPharmacyPatient();

  useEffect(() => {
    if (user?._id && pharmacy) {
      addPharmacyPatient.mutate({
        memberID: user._id,
        pharmacyID: pharmacy,
      });
    }
    // eslint-disable-next-line
  }, [pharmacy, user]);

  const handleSubmitFeedback = async (form: TFeedbackRequest) => {
    await submitFeedback.mutate(form);
  };

  const handleReturnHome = () => {
    history.push(ROUTES.DASHBOARD);
  };

  return (
    <Container className={classes.container}>
      <Grid container item xl={6} lg={8} md={10}>
        <CompleteSurvey
          cta="RETURN HOME"
          title="Great! You’ve selected your pharmacy."
          message="Please feel free to reach out to your care coordinator with with any questions or concerns about your prescriptions and pharmacy preference."
          feedbackTitle="How was your experience with this form?"
          onClickCta={handleReturnHome}
          onSubmitFeedback={handleSubmitFeedback}
        />
      </Grid>
    </Container>
  );
};

export default PharmacyCompletePage;
