import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import { ROUTES } from "src/app/types";
import { useAllCareProviderList } from "src/api/providerApi";
import Container from "src/components/Container";
import { useLayoutStyles } from "src/components/useCommonStyles";
import BookingSearchBar from "src/components/Booking/BookingSearchBar";
import SmallCareProviderCard from "src/components/CareProvider/SmallCareProviderCard";

import { CARE_PROVIDER_TYPES } from "./constants";
import {
  useBookingListQuery,
  useCareMemberAvailableTimes,
  useFilterData,
  useFilteredCareProviderList,
} from "./hooks";

const BookingListPage: FC = () => {
  const history = useHistory();
  const layoutClasses = useLayoutStyles();

  const { data: careProviders = [] } = useAllCareProviderList();
  const { recordsByUserId, fetchNewRecords } = useCareMemberAvailableTimes();

  const { searchForm, handleChangeQuery } = useBookingListQuery();
  const { insurances, languages, states } = useFilterData({ careProviders });
  const { filteredCareProviders } = useFilteredCareProviderList({
    careProviders,
    recordsByUserId,
    searchForm,
  });

  useEffect(() => {
    filteredCareProviders.forEach((provider) => {
      fetchNewRecords(provider._id);
    });
  }, [fetchNewRecords, filteredCareProviders]);

  const handleClickViewProfile = (providerId: string) => {
    history.push(`${ROUTES.BOOKING}/${providerId}`);
  };

  return (
    <Container showIcon>
      <Grid item xs={12} className={layoutClasses.mb4}>
        <BookingSearchBar
          searchForm={searchForm}
          insurances={insurances}
          languages={languages}
          providerTypes={CARE_PROVIDER_TYPES}
          states={states}
          showCount={3}
          onChange={handleChangeQuery}
        />
      </Grid>

      <Grid item xs={12}>
        {filteredCareProviders.map((provider) => (
          <SmallCareProviderCard
            key={provider._id}
            className={layoutClasses.mb2}
            careProvider={provider}
            onClickProfile={handleClickViewProfile}
          />
        ))}
      </Grid>
    </Container>
  );
};

export default BookingListPage;
