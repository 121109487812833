import dayjs from "dayjs";
import { useMemo } from "react";

import {
  BookingSearchForm,
  ICareMember,
  ICareMemberWithAvailability,
  TCareMemberAvailabilityRecord,
} from "src/types";
import { formatUserType } from "src/utils";

import { CARE_PROVIDER_TYPES } from "../constants";

interface IUseFilteredCareProviderListData {
  careProviders: ICareMember[];
  recordsByUserId: Record<string, TCareMemberAvailabilityRecord>;
  searchForm: BookingSearchForm;
}

const useFilteredCareProviderList = ({
  careProviders,
  recordsByUserId,
  searchForm: { type, insurance, language, state },
}: IUseFilteredCareProviderListData) => {
  const filteredCareProviders = useMemo(() => {
    return careProviders
      .filter((careProvider) => {
        if (careProvider.hidden) {
          return false;
        }
        const userType = formatUserType(careProvider.userType);
        if (type.length) {
          return type.some((each) => each === userType);
        }
        return CARE_PROVIDER_TYPES.some((type) => type === userType);
      })
      .map((careProvider) => {
        const matchings = [];
        let isMatched = true;

        const availabilityRecord = recordsByUserId[careProvider._id] || {
          isLoading: true,
          data: null,
        };

        const isAvailabilityLoading = availabilityRecord.isLoading;
        const availableDate = availabilityRecord.data?.[0]?.date;
        const isNotAvailable =
          availabilityRecord.error ||
          (!availabilityRecord.isLoading && !availabilityRecord.data?.length);

        if (insurance) {
          if (careProvider.insurance.some((each) => each.type === insurance)) {
            matchings.push(`Accepts ${insurance}`);
          } else {
            isMatched = false;
          }
        }

        if (language) {
          if (careProvider.language.some((each) => each === language)) {
            matchings.push(`Speaks ${language}`);
          } else {
            isMatched = false;
          }
        }

        if (state) {
          if (careProvider.state.some((each) => each === state)) {
            matchings.push(`Accepts patients in ${state}`);
          } else {
            isMatched = false;
          }
        }

        return {
          ...careProvider,
          availableDate,
          isNotAvailable,
          isAvailabilityLoading,
          matchings,
          isMatched,
        };
      })
      .filter((provider) => provider.isMatched)
      .sort((provider1, provider2) => {
        if (provider1.isNotAvailable && !provider2.isNotAvailable) {
          return 1;
        }

        if (!provider1.isNotAvailable && provider2.isNotAvailable) {
          return -1;
        }

        if (!provider1.isNotAvailable && !provider2.isNotAvailable) {
          if (
            !provider1.isAvailabilityLoading &&
            provider2.isAvailabilityLoading
          ) {
            return -1;
          }

          if (
            provider1.isAvailabilityLoading &&
            !provider2.isAvailabilityLoading
          ) {
            return 1;
          }

          return dayjs(provider1.availableDate).diff(
            provider2.availableDate,
            "days"
          );
        }

        return provider2.matchings.length - provider1.matchings.length;
      }) as ICareMemberWithAvailability[];
  }, [careProviders, recordsByUserId, type, insurance, language, state]);

  return {
    filteredCareProviders,
  };
};

export default useFilteredCareProviderList;
