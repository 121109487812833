export enum PRESCRIPTION_DELIVERY_OPTIONS {
  HOME_DELIVERY = "HOME_DELIVERY",
  PHARMACY_PICKUP = "PHARMACY_PICKUP",
}

export type TPharmacy = {
  StoreName: string;
  Address1: string;
  Address2?: string;
  City: string;
  State: string;
  ZipCode: string;
  PrimaryPhone: string;
  PharmacyId: string;
};

export type TPharmacySearchForm = {
  pharmacyName: string;
  pharmacyZip: string;
};

export type TPharmacyRegistrationDto = {
  memberID: string;
  pharmacyID: string | number;
};
