import { SeverityTypes, ICareMember, TProgress } from "src/types";

export const formatPhoneNumber = (value: string): string => {
  const tempValue = value.replace(/\D[^.]/g, "");
  if (tempValue.length < 9) return value;
  return [
    tempValue.slice(0, 3),
    tempValue.slice(3, 6),
    tempValue.slice(6),
  ].join("-");
};

export const formatUserType = (userType: ICareMember["userType"]) => {
  switch (userType) {
    case "cc":
      return "Care Coordinator";
    case "psych":
      return "Psychiatrist";
    case "therapist":
      return "Therapist";
    case "Care Partner":
      return "Care Partner";
    default:
      return userType;
  }
};

export const formatUserNameAndTitle = (member: ICareMember) =>
  [[member.firstName, member.lastName].join(" "), ...member.title].join(", ");

export const getSeverityBasedOnScore = (score: TProgress): SeverityTypes => {
  if (score.totalGAD >= 15 || score.totalPHQ >= 20) {
    return SeverityTypes.SEVERE;
  }
  if (score.totalGAD >= 10 || score.totalPHQ >= 15) {
    return SeverityTypes.MODERATE_SEVERE;
  }
  if (score.totalGAD >= 5 || score.totalPHQ >= 10) {
    return SeverityTypes.MODERATE;
  }
  return SeverityTypes.MINIMAL;
};
