import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { serializeQueryParameters } from "src/api/useApiFetch";
import { useQueryParams } from "src/api/useQueryParams";
import { ROUTES } from "src/app/types";
import { BookingSearchForm } from "src/types";

const useBookingListQuery = () => {
  const history = useHistory();
  const queryParams = useQueryParams();

  const searchForm: BookingSearchForm = useMemo(() => {
    const type = queryParams.get("type")?.split(",") || [];
    const insurance = queryParams.get("insurance") || "";
    const language = queryParams.get("language") || "";
    const state = queryParams.get("state") || "";

    return { type, insurance, language, state };
  }, [queryParams]);

  const handleChangeQuery = useCallback(
    (form: BookingSearchForm) => {
      history.push(
        `${ROUTES.BOOKING_LIST}${serializeQueryParameters({
          ...form,
          type: form.type.join(","),
        })}`
      );
    },
    [history]
  );

  return { searchForm, handleChangeQuery };
};

export default useBookingListQuery;
