import { FC } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import Container from "src/components/Container";
import { PrescriptionDelivery } from "src/components/pharmacy";
import { Theme } from "src/theme/types/createPalette";

import { DEFAULT_PHARMACY } from "src/constants";
import { PRESCRIPTION_DELIVERY_OPTIONS } from "src/types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "src/app/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.backgroundGreen.main,
    },
  })
);

const PharmacyPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleSelectDeliveryOption = (
    option: PRESCRIPTION_DELIVERY_OPTIONS
  ) => {
    if (option === PRESCRIPTION_DELIVERY_OPTIONS.HOME_DELIVERY) {
      history.push(`${ROUTES.PHARMACY_COMPLETE}?pharmacy=${DEFAULT_PHARMACY}`);
    } else {
      history.push(ROUTES.PHARMACY_SEARCH);
    }
  };

  return (
    <Container className={classes.container}>
      <Grid container item xl={6} lg={8} md={10}>
        <PrescriptionDelivery onNext={handleSelectDeliveryOption} />
      </Grid>
    </Container>
  );
};

export default PharmacyPage;
