import clsx from "clsx";
import { FC, FormEventHandler } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import Button from "src/components/Button";
import TextInput from "src/components/TextInput";
import { useLayoutStyles } from "src/components/useCommonStyles";
import { Theme } from "src/theme/types/createPalette";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0.15,
      color: theme.palette.primaryNavy.main,
    },
    pharmacyLabel: {
      marginBottom: theme.spacing(6),
    },
    helperText: {
      color: theme.palette.secondaryNavy1.main,
      marginTop: theme.spacing(0.5),
    },
  })
);

interface PharmacySelectionProps {
  defaultValues: {
    pharmacyName: string;
    pharmacyZip: string;
  };
  onBack: () => void;
  onSearch: (pharmacyName: string, pharmacyZip: string) => void;
}

const PharmacySelection: FC<PharmacySelectionProps> = ({
  defaultValues,
  onBack,
  onSearch,
}) => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();

  const methods = useForm({
    mode: "onChange",
    defaultValues,
  });

  const pharmacyName = methods.getValues("pharmacyName");
  const pharmacyZip = methods.getValues("pharmacyZip");

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
  };

  const handleSearch = () => {
    onSearch(pharmacyName, pharmacyZip);
  };

  return (
    <Grid container>
      <Grid item xs={12} className={layoutClasses.mb8}>
        <Typography variant="h3">
          Which pharmacy should we send your prescription to?
        </Typography>
      </Grid>

      <form onSubmit={handleSubmit} className={layoutClasses.fullWidth}>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                name="pharmacyName"
                fullWidth
                label="What’s the name of the pharmacy?"
                labelClass={clsx(classes.label, classes.pharmacyLabel)}
                placeholder="Pharmacy Name"
                helperText={
                  <div className={classes.helperText}>
                    <Typography variant="body1">
                      You can search by providing the first few letters of the
                      pharmacy’s name.
                    </Typography>
                    <Typography variant="body1">
                      (ex. “CVS” instead of typing “CVS Pharmacy”)
                    </Typography>
                  </div>
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                name="pharmacyZip"
                fullWidth
                label="What’s the ZIP Code of the pharmacy?"
                labelClass={classes.label}
                placeholder="ZIP Code"
              />
            </Grid>
          </Grid>
        </FormProvider>
      </form>

      <Grid container justify="center" className={layoutClasses.mt2}>
        <Grid item xs={4}>
          <Button
            text="Search"
            type="submit"
            disabled={!pharmacyName || !pharmacyZip}
            color="primary"
            variant="contained"
            onClick={handleSearch}
          />
        </Grid>
      </Grid>
      <Grid container justify="center" className={layoutClasses.mt2}>
        <Grid item xs={4}>
          <Button text="Back" color="primary" variant="text" onClick={onBack} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PharmacySelection;
