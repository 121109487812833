export const validateBirthday = {
  required: "Birthday is required.",
  validate: {
    valid: (value: any) => {
      const [year, month, day] = value
        .split("-")
        .map((each: string) => Number(each));
      const isValid =
        year >= 1900 &&
        year <= new Date().getFullYear() &&
        month >= 1 &&
        month <= 12 &&
        day >= 1 &&
        day <= 31;
      return isValid ? true : "Birthday is invalid.";
    },
  },
};

export const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const phoneNumberPattern =
  /^(\([0-9]{3}\)\s*|[0-9]{3}[-]?)[0-9]{3}[-]?[0-9]{4}$/;

export const usZipcodePattern = /^\d{5}$|^\d{5}-\d{4}$/;

export const feetInchPattern = /^\d{1,2}$/;
export const validateEmail = (email: string): boolean =>
  emailPattern.test(email);

export const validatePhoneNumber = (phoneNumber: string): boolean =>
  phoneNumberPattern.test(phoneNumber);
