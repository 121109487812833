import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

import { ROUTES } from "src/app/types";
import {
  useQueryParams,
  useSearchPharmaciesMutation,
  serializeQueryParameters,
} from "src/api";
import { QUERY_PARAMS } from "src/constants";
import { useNotification } from "src/hooks/useNotification";
import { TPharmacy } from "src/types";
import { isArray } from "lodash";

export const DEFAULT_SHOW_COUNT = 5;

const useSearchPharmacies = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const searchPharmaciesMutation = useSearchPharmaciesMutation();

  const pharmacyName = queryParams.get(QUERY_PARAMS.PHARMACY_NAME) || null;
  const pharmacyZip = queryParams.get(QUERY_PARAMS.PHARMACY_ZIP) || null;

  const [results, setResults] = useState<TPharmacy[]>([]);
  const [loading, setLoading] = useState(false);
  const [showCount, setShowCount] = useState(DEFAULT_SHOW_COUNT);
  const { handleError } = useNotification();

  const filteredResults = useMemo(() => {
    return results.slice(0, showCount);
  }, [results, showCount]);

  const doSearch = useCallback(() => {
    if (!pharmacyName || !pharmacyZip) return;

    setLoading(true);
    setShowCount(0);

    searchPharmaciesMutation.mutate(
      {
        pharmacyName,
        pharmacyZip,
      },
      {
        onSuccess: (data) => {
          if (!isArray(data) || !data.length) {
            handleError(
              null,
              "Pharmacy not found. Please modify your search and try again."
            );

            return;
          }

          setResults(data);
          setShowCount(Math.min(data.length, DEFAULT_SHOW_COUNT));
        },
        onSettled: () => {
          setLoading(false);
        },
      }
    );
    // eslint-disable-next-line
  }, [pharmacyName, pharmacyZip]);

  useEffect(() => {
    doSearch();
  }, [doSearch]);

  const handleSearch = useCallback(
    (pharmacyName: string, pharmacyZip: string) => {
      history.push(
        `${ROUTES.PHARMACY_SEARCH}${serializeQueryParameters({
          [QUERY_PARAMS.PHARMACY_NAME]: pharmacyName,
          [QUERY_PARAMS.PHARMACY_ZIP]: pharmacyZip,
        })}`
      );
    },
    [history]
  );

  const handleLoadMore = useCallback(() => {
    setShowCount((showCount) =>
      Math.min(showCount + DEFAULT_SHOW_COUNT, results.length)
    );
  }, [results]);

  const handleSearchBack = useCallback(() => {
    setResults([]);
  }, []);

  return {
    results: filteredResults,
    loading,
    remainingCount: Math.max(results.length - showCount, 0),
    pharmacyName,
    pharmacyZip,
    onSearch: handleSearch,
    onSearchBack: handleSearchBack,
    onLoadMore: handleLoadMore,
  };
};

export default useSearchPharmacies;
