import _ from "lodash";
import { useMemo } from "react";

import { ICareMember } from "src/types";

interface IUseFilterData {
  careProviders: ICareMember[];
}

const useFilterData = ({ careProviders }: IUseFilterData) => {
  const { insurances, languages, states } = useMemo(
    () => ({
      insurances: _(careProviders)
        .flatMap("insurance")
        .map("type")
        .uniq()
        .value(),
      languages: _(careProviders).flatMap("language").uniq().value(),
      states: _(careProviders).flatMap("state").uniq().value(),
    }),
    [careProviders]
  );

  return {
    insurances,
    languages,
    states,
  };
};

export default useFilterData;
