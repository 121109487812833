export enum ROUTES {
  LOGIN = "/login",
  REGISTER = "/register",
  RESET_PASSWORD = "/reset-password",
  VERIFICATION_LINK = "/verification-link",
  DASHBOARD = "/dashboard",
  PROFILE = "/profile",
  ASSESSMENT = "/assessment",
  PROGRESS = "/progress",
  INTAKE_FORM = "/intake-form",
  CARE_COORDINATION = "/care-coordination",
  PHARMACY = "/pharmacy",
  PHARMACY_SEARCH = "/pharmacy/search",
  PHARMACY_COMPLETE = "/pharmacy/complete",
  BOOKING = "/booking",
  BOOKING_LIST = "/booking/list",
}
