import { useMutation } from "react-query";

import { QUERY_KEYS } from "./constants";
import { useApiFetch } from "./useApiFetch";
import {
  TPharmacy,
  TPharmacySearchForm,
  TOperationResultDto,
  TPharmacyRegistrationDto,
} from "src/types";

export const useSearchPharmaciesMutation = () => {
  const apiFetch = useApiFetch();

  return useMutation(
    [QUERY_KEYS.SEARCH_PHARMACIES],
    async (
      form: TPharmacySearchForm
    ): Promise<TPharmacy[] | TOperationResultDto> => {
      const { data } = await apiFetch("/dosespot/pharmacies/search", {
        method: "POST",
        data: form,
      });
      return data;
    }
  );
};

export const useAddPharmacyPatient = () => {
  const apiFetch = useApiFetch();

  return useMutation(
    [QUERY_KEYS.REGISTER_DOSESPOT_PATIENT],
    async (
      form: TPharmacyRegistrationDto
    ): Promise<TPharmacy[] | TOperationResultDto> => {
      const { data } = await apiFetch(
        `/dosespot/pharmacies/patient/preferred/null&${form.memberID}`,
        {
          method: "POST",
          data: {
            pharmacyID: form.pharmacyID,
          },
        }
      );
      return data;
    }
  );
};
