import clsx from "clsx";
import { FC } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import Button from "src/components/Button";
import { useFontStyles, useLayoutStyles } from "src/components/useCommonStyles";
import FeedbackForm, {
  DEFAULT_FEEDBACK_TITLE,
} from "src/components/FeedbackForm";
import { useViewport } from "src/hooks/useViewport";
import { TFeedbackRequest } from "src/types";

const useStyles = makeStyles((theme) =>
  createStyles({
    feedbackFormWrapper: {
      position: "fixed",
      left: 0,
      bottom: theme.spacing(4),
    },
  })
);

interface CompleteSurveyProps {
  cta: string;
  title: string;
  message: string;
  feedbackTitle?: string;
  onClickCta: VoidFunction;
  onSubmitFeedback: (form: TFeedbackRequest) => void;
}

const CompleteSurvey: FC<CompleteSurveyProps> = ({
  cta,
  title,
  message,
  feedbackTitle = DEFAULT_FEEDBACK_TITLE,
  onClickCta,
  onSubmitFeedback,
}) => {
  const classes = useStyles();
  const fontClasses = useFontStyles();
  const layoutClasses = useLayoutStyles();
  const { isMobile } = useViewport();

  return (
    <Grid container>
      <Grid
        container
        justify="center"
        item
        xs={12}
        className={layoutClasses.mb3}
      >
        <img src="/images/celebration.png" height={120} alt="Celebration" />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          align="center"
          className={clsx(fontClasses.fontNormal, layoutClasses.mb2)}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={isMobile ? layoutClasses.mb4 : layoutClasses.mb6}
      >
        <Typography variant="subtitle1" align="center">
          {message}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          text={cta}
          color="primary"
          type="submit"
          variant="contained"
          onClick={onClickCta}
        />
      </Grid>
      <Grid
        container
        justify="center"
        item
        xs={12}
        className={classes.feedbackFormWrapper}
      >
        <FeedbackForm
          title={feedbackTitle}
          onSubmitFeedback={onSubmitFeedback}
        />
      </Grid>
    </Grid>
  );
};

export default CompleteSurvey;
