import clsx from "clsx";
import { FC } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { TPharmacy } from "src/types";
import { TextButton } from "src/components/Button";
import {
  useColorStyles,
  useFontStyles,
  useLayoutStyles,
} from "src/components/useCommonStyles";
import { Theme } from "src/theme/types/createPalette";
import { formatPhoneNumber } from "src/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: "white",
      border: `1px solid ${theme.palette.distinctiveGray.main}`,
      borderRadius: theme.spacing(1),
      cursor: "pointer",
      padding: theme.spacing(3),
      position: "relative",

      display: "flex",
    },

    informationBox: {
      flex: 1,
      width: 100,
    },
  })
);

interface PharmacyCardProps {
  pharmacy: TPharmacy;
  onSelect: (pharmacy: TPharmacy) => void;
}

const PharmacyCard: FC<PharmacyCardProps> = ({ pharmacy, onSelect }) => {
  const classes = useStyles();
  const fontClasses = useFontStyles();
  const colorClasses = useColorStyles();
  const layoutClasses = useLayoutStyles();

  const address = [
    pharmacy.Address1,
    pharmacy.Address2,
    pharmacy.City,
    pharmacy.State,
    pharmacy.ZipCode,
  ]
    .filter((each) => each)
    .join(", ");

  const handleSelect = () => {
    onSelect(pharmacy);
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.informationBox}>
        <Typography
          variant="h6"
          className={clsx(fontClasses.fontBolder, layoutClasses.mb1)}
        >
          {pharmacy.StoreName}
        </Typography>
        <Typography variant="subtitle2" className={colorClasses.secondaryNavy1}>
          {address}
        </Typography>
        <Typography variant="subtitle2" className={colorClasses.secondaryNavy1}>
          {formatPhoneNumber(pharmacy.PrimaryPhone)}
        </Typography>
      </Box>

      <TextButton
        text="SELECT"
        variant="text"
        color="primary"
        fullWidth={false}
        onClick={handleSelect}
      />
    </Box>
  );
};

export default PharmacyCard;
