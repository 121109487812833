import { TPosition } from "src/types";

export const getCapitalizedValue = (value: string) => {
  if (value.length) return value[0].toUpperCase() + value.substr(1);
  return value;
};

export const getCapitalizedFirstLetters = (values: string[]) =>
  values.map((value) => {
    if (value.length) return value[0].toUpperCase();
    return "";
  });

export const getUniqueGraphValues = (positions: TPosition[]) =>
  positions.filter(
    (position, index) => index <= 0 || position.x !== positions[index - 1].x
  );

export const getFormErrorMessages = (errors: any): string[] => {
  if (!errors) return [];

  let messages: string[] = [];
  Object.values(errors).forEach((error: any) => {
    if (error.message) {
      messages.push(error.message);
    } else if (typeof error === "string") {
      messages.push(error);
    } else if (typeof error !== "function") {
      messages = [...messages, ...getFormErrorMessages(error)];
    }
  });
  return messages;
};
