import { FC } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { ROUTES } from "src/app/types";
import Container from "src/components/Container";
import {
  PharmacySelection,
  PharmacySearchResults,
} from "src/components/pharmacy";
import { Theme } from "src/theme/types/createPalette";
import { TPharmacy } from "src/types";

import { useSearchPharmacies, DEFAULT_SHOW_COUNT } from "./hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.backgroundGreen.main,
    },
  })
);

const PharmacySearchPage: FC = () => {
  const classes = useStyles();

  const history = useHistory();

  const {
    results,
    loading,
    remainingCount,
    pharmacyName,
    pharmacyZip,
    onSearchBack,
    onSearch,
    onLoadMore,
  } = useSearchPharmacies();

  const onSelectPharmacy = (pharmacy: TPharmacy) => {
    history.push(`${ROUTES.PHARMACY_COMPLETE}?pharmacy=${pharmacy.PharmacyId}`);
  };

  const handleBackSelection = () => {
    history.push(ROUTES.PHARMACY);
  };

  return (
    <Container className={classes.container}>
      <Grid container item xl={6} lg={8} md={10}>
        {!results.length ? (
          <PharmacySelection
            defaultValues={{
              pharmacyName: pharmacyName || "",
              pharmacyZip: pharmacyZip || "",
            }}
            onBack={handleBackSelection}
            onSearch={onSearch}
          />
        ) : (
          <PharmacySearchResults
            defaultLoadMoreCount={DEFAULT_SHOW_COUNT}
            loading={loading}
            results={results}
            remainingCount={remainingCount}
            onLoadMore={onLoadMore}
            onBack={onSearchBack}
            onSelect={onSelectPharmacy}
          />
        )}
      </Grid>
    </Container>
  );
};

export default PharmacySearchPage;
