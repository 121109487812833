import { FC } from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import Button from "src/components/Button";
import { useLayoutStyles } from "src/components/useCommonStyles";
import { TPharmacy } from "src/types";

import PharmacyCard from "./PharmacyCard";

interface PharmacySearchResultsProps {
  defaultLoadMoreCount: number;
  loading: boolean;
  results: TPharmacy[];
  remainingCount: number;
  onLoadMore: VoidFunction;
  onBack: VoidFunction;
  onSelect: (pharmacy: TPharmacy) => void;
}

const PharmacySearchResults: FC<PharmacySearchResultsProps> = ({
  defaultLoadMoreCount,
  loading,
  results,
  remainingCount,
  onLoadMore,
  onBack,
  onSelect,
}) => {
  const layoutClasses = useLayoutStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={layoutClasses.mb4}>
        <Typography variant="h3">Select your pharmacy.</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3}>
          {results.map((result) => (
            <Grid key={result.PharmacyId} item xs={12}>
              <PharmacyCard pharmacy={result} onSelect={onSelect} />
            </Grid>
          ))}
        </Grid>
      </Grid>

      {loading && <CircularProgress />}

      {remainingCount > 0 && (
        <Grid container justify="center" className={layoutClasses.mt2}>
          <Grid item xs={4}>
            <Button
              text={`LOAD ${Math.min(
                remainingCount,
                defaultLoadMoreCount
              )} MORE RESULTS`}
              type="submit"
              color="primary"
              variant="text"
              onClick={onLoadMore}
            />
          </Grid>
        </Grid>
      )}

      <Grid container justify="center" className={layoutClasses.mt2}>
        <Grid item xs={4}>
          <Button text="Back" color="default" variant="text" onClick={onBack} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PharmacySearchResults;
