import { FC } from "react";
import Grid from "@material-ui/core/Grid";

import { useLayoutStyles } from "src/components/useCommonStyles";
import {
  ICareMember,
  TCareMemberAvailableDate,
  TDropItem,
  IProfileSetUpCardForm,
} from "src/types";

import BookAppointmentCard from "./BookAppointmentCard";
import ProfileSetUpCard from "./ProfileSetUpCard";

interface CareProviderInTakeProps {
  availableDates: TCareMemberAvailableDate[];
  careProvider: ICareMember;
  selectedTime: string;
  payerOptions: TDropItem[];
  onSelect: (time: string) => void;
  onSubmit: (form: IProfileSetUpCardForm) => void;
}

const CareProviderInTake: FC<CareProviderInTakeProps> = ({
  availableDates,
  careProvider,
  selectedTime,
  payerOptions,
  onSelect,
  onSubmit,
}) => {
  const layoutClasses = useLayoutStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={layoutClasses.mt6}>
        <BookAppointmentCard
          availableDates={availableDates}
          careProvider={careProvider}
          selectedTime={selectedTime}
          onSelect={onSelect}
        />
      </Grid>

      <Grid item xs={12} className={layoutClasses.mt4}>
        <ProfileSetUpCard
          selectedTime={selectedTime}
          payerOptions={payerOptions}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CareProviderInTake;
