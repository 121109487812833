import { useCallback, useState } from "react";

import { useApiFetch } from "src/api/useApiFetch";
import { TCareMemberAvailabilityRecord } from "src/types";

const useCareMemberAvailableTimes = () => {
  const apiFetch = useApiFetch();

  const [recordsByUserId, setRecordsByUserId] = useState<
    Record<string, TCareMemberAvailabilityRecord>
  >({});

  const fetchNewRecords = useCallback(
    async (userId: string) => {
      if (recordsByUserId[userId]) return;

      try {
        setRecordsByUserId((recordsByUserId) => ({
          ...recordsByUserId,
          [userId]: {
            isLoading: true,
            data: null,
          },
        }));

        const { data } = await apiFetch(`/acuity/${userId}/available-dates`);

        setRecordsByUserId((recordsByUserId) => ({
          ...recordsByUserId,
          [userId]: {
            isLoading: false,
            data,
          },
        }));
      } catch (error) {
        setRecordsByUserId((recordsByUserId) => ({
          ...recordsByUserId,
          [userId]: {
            isLoading: false,
            error: true,
            data: null,
          },
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recordsByUserId]
  );

  return { recordsByUserId, fetchNewRecords };
};

export default useCareMemberAvailableTimes;
