export enum QUERY_KEYS {
  FETCH_MEMBER = "FETCH_MEMBER",
  FETCH_MEMBER_TODOS = "FETCH_MEMBER_TODOS",
  FETCH_CARE_TEAM_LIST = "FETCH_CARE_TEAM_LIST",
  FETCH_SCORE_PROGRESS_HISTORY = "FETCH_SCORE_PROGRESS_HISTORY",
  FETCH_CARE_PROVIDER = "FETCH_CARE_PROVIDER",
  FETCH_CARE_PROVIDER_LIST = "FETCH_CARE_PROVIDER_LIST",
  FETCH_PAYER_LIST = "FETCH_PAYER_LIST",
  FETCH_PROVIDER_AVAILABLE_DATES = "FETCH_PROVIDER_AVAILABLE_DATES",
  FETCH_PROVIDER_AVAILABLE_TIMES = "FETCH_PROVIDER_AVAILABLE_TIMES",
  CREATE_PROGRESS = "CREATE_PROGRESS",
  CHECK_TRIGGER_REQUEST = "CHECK_TRIGGER_REQUEST",
  CHECK_ASSESSMENT_LINK = "CHECK_ASSESSMENT_LINK",
  UPLOAD_MEMBER_AVATAR = "UPLOAD_MEMBER_AVATAR",
  UPDATE_PROFILE_FORM = "UPDATE_PROFILE_FORM",
  UPDATE_ASSESSMENT_FORM = "UPDATE_ASSESSMENT_FORM",
  UPDATE_INTAKE_FORM = "UPDATE_INTAKE_FORM",
  UPDATE_COORDINATION_FORM = "UPDATE_COORDINATION_FORM",
  UPLOAD_FILE = "UPLOAD_FILE",
  SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK",
  BOOK_APPOINTMENT = "BOOK_APPOINTMENT",
  SEARCH_PHARMACIES = "SEARCH_PHARMACIES",
  REGISTER_DOSESPOT_PATIENT = "REGISTER_DOSESPOT_PATIENT",
}

export enum QUERY_STATUS {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
